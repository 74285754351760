@use 'assets/scss/variables';
@import 'primeicons/primeicons.css';

/* Layout */
@import './assets/styles/layout/layout.scss';

* {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: 16px;
}

a {
    text-decoration: none;
}

.layout-container {
    min-height: 100vh;
}
