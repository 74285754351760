$primary: #4958fc;
$primaryColor: #4958fc;
$primary-light: rgba($primary, 0.16);
$dark: #141820;
$gray: #626262;
$light: #fff;
$body: #f4f4f4;
$danger: #ff3f3c;
$warning: #d68000;
$danger-light: rgba(#ff3f3c, 0.16);
$radius: 4px;
$shadow: 1px 1px 2px rgba($dark, 0.07);
$card-padding: 0.6rem 1rem;

//reused color variables
$shade000: #ffffff !default; //surface
$shade100: #f9fafb !default; //header background
$shade200: #f3f4f6 !default; //hover background
$shade300: #e5e7eb !default; //border, divider
$shade400: #d1d5db !default; //input border
$shade500: #9ca3af !default; //input icon
$shade600: #6b7280 !default; //text secondary color
$shade700: #4b5563 !default; //text color
$shade800: #374151 !default; //unused
$shade900: #1f2937 !default; //unused
$hoverBg: rgba(255, 255, 255, 0.03) !default;

$infoButtonBg: #93c5fd !default;
$infoButtonTextColor: #1c2127 !default;
$infoButtonBorder: 1px solid $infoButtonBg !default;
$infoButtonHoverBg: scale-color($infoButtonBg, $lightness: -10%) !default;
$infoButtonTextHoverColor: $infoButtonTextColor !default;
$infoButtonHoverBorderColor: scale-color($infoButtonBg, $lightness: -10%) !default;
$infoButtonActiveBg: scale-color($infoButtonBg, $lightness: -20%) !default;
$infoButtonTextActiveColor: $infoButtonTextColor !default;
$infoButtonActiveBorderColor: scale-color($infoButtonBg, $lightness: -20%) !default;
$infoButtonFocusShadow: 0 0 0 1px scale-color($infoButtonBg, $lightness: 30%) !default;

$secondaryButtonBg: #cbd5e1 !default;
$secondaryButtonTextColor: #1c2127 !default;
$secondaryButtonBorder: 1px solid $secondaryButtonBg !default;
$secondaryButtonHoverBg: scale-color($secondaryButtonBg, $lightness: -10%) !default;
$secondaryButtonTextHoverColor: $secondaryButtonTextColor !default;
$secondaryButtonHoverBorderColor: scale-color($secondaryButtonBg, $lightness: -10%) !default;
$secondaryButtonActiveBg: scale-color($secondaryButtonBg, $lightness: -20%) !default;
$secondaryButtonTextActiveColor: $secondaryButtonTextColor !default;
$secondaryButtonActiveBorderColor: scale-color($secondaryButtonBg, $lightness: -20%) !default;
$secondaryButtonFocusShadow: 0 0 0 1px scale-color($secondaryButtonBg, $lightness: 30%) !default;

$successButtonBg: #1eac52 !default;
$successButtonTextColor: #1c2127 !default;
$successButtonBorder: 1px solid $successButtonBg !default;
$successButtonHoverBg: scale-color($successButtonBg, $lightness: -10%) !default;
$successButtonTextHoverColor: $successButtonTextColor !default;
$successButtonHoverBorderColor: scale-color($successButtonBg, $lightness: -10%) !default;
$successButtonActiveBg: scale-color($successButtonBg, $lightness: -20%) !default;
$successButtonTextActiveColor: $successButtonTextColor !default;
$successButtonActiveBorderColor: scale-color($successButtonBg, $lightness: -20%) !default;
$successButtonFocusShadow: 0 0 0 1px scale-color($successButtonBg, $lightness: 30%) !default;

$warningButtonBg: #fcd34d !default;
$warningButtonTextColor: #1c2127 !default;
$warningButtonBorder: 1px solid $warningButtonBg !default;
$warningButtonHoverBg: scale-color($warningButtonBg, $lightness: -10%) !default;
$warningButtonTextHoverColor: $warningButtonTextColor !default;
$warningButtonHoverBorderColor: scale-color($warningButtonBg, $lightness: -10%) !default;
$warningButtonActiveBg: scale-color($warningButtonBg, $lightness: -20%) !default;
$warningButtonTextActiveColor: $warningButtonTextColor !default;
$warningButtonActiveBorderColor: scale-color($warningButtonBg, $lightness: -20%) !default;
$warningButtonFocusShadow: 0 0 0 1px scale-color($warningButtonBg, $lightness: 30%) !default;

$helpButtonBg: #d8b4fe !default;
$helpButtonTextColor: #1c2127 !default;
$helpButtonBorder: 1px solid $helpButtonBg !default;
$helpButtonHoverBg: scale-color($helpButtonBg, $lightness: -10%) !default;
$helpButtonTextHoverColor: $helpButtonTextColor !default;
$helpButtonHoverBorderColor: scale-color($helpButtonBg, $lightness: -10%) !default;
$helpButtonActiveBg: scale-color($helpButtonBg, $lightness: -20%) !default;
$helpButtonTextActiveColor: $helpButtonTextColor !default;
$helpButtonActiveBorderColor: scale-color($helpButtonBg, $lightness: -20%) !default;
$helpButtonFocusShadow: 0 0 0 1px scale-color($helpButtonBg, $lightness: 30%) !default;

$dangerButtonBg: #fca5a5 !default;
$dangerButtonTextColor: #1c2127 !default;
$dangerButtonBorder: 1px solid $dangerButtonBg !default;
$dangerButtonHoverBg: scale-color($dangerButtonBg, $lightness: -10%) !default;
$dangerButtonTextHoverColor: $dangerButtonTextColor !default;
$dangerButtonHoverBorderColor: scale-color($dangerButtonBg, $lightness: -10%) !default;
$dangerButtonActiveBg: scale-color($dangerButtonBg, $lightness: -20%) !default;
$dangerButtonTextActiveColor: $dangerButtonTextColor !default;
$dangerButtonActiveBorderColor: scale-color($dangerButtonBg, $lightness: -20%) !default;
$dangerButtonFocusShadow: 0 0 0 1px scale-color($dangerButtonBg, $lightness: 30%) !default;

//panel
$panelHeaderBorderColor: $shade600 !default;
$panelHeaderBorder: 1px solid $shade600 !default;
$panelHeaderBg: $shade800 !default;
$panelHeaderTextColor: $shade000 !default;
$panelHeaderFontWeight: 700 !default;
$panelHeaderPadding: 1.25rem !default;
$panelToggleableHeaderPadding: 0.75rem 1.25rem !default;

$panelHeaderHoverBg: $hoverBg !default;
$panelHeaderHoverBorderColor: $shade600 !default;
$panelHeaderTextHoverColor: $shade000 !default;

$panelContentBorderColor: $shade600 !default;
$panelContentBorder: 1px solid $shade600 !default;
$panelContentBg: $shade800 !default;
$panelContentEvenRowBg: rgba(255, 255, 255, 0.01) !default;
$panelContentTextColor: $shade000 !default;
$panelContentPadding: 1.25rem !default;

$panelFooterBorder: 1px solid $shade600 !default;
$panelFooterBg: $shade800 !default;
$panelFooterTextColor: $shade000 !default;
$panelFooterPadding: 0.75rem 1.25rem !default;

//global
$fontFamily: 'Poppins', sans-serif !default;
$fontSize: 1rem !default;
$fontWeight: normal !default;
$textColor: $shade700 !default;
$textSecondaryColor: $shade600 !default;
$borderRadius: 6px !default;
$transitionDuration: 0.2s !default;
$formElementTransition:
    background-color $transitionDuration,
    color $transitionDuration,
    border-color $transitionDuration,
    box-shadow $transitionDuration !default;
$actionIconTransition:
    background-color $transitionDuration,
    color $transitionDuration,
    box-shadow $transitionDuration !default;
$listItemTransition: box-shadow $transitionDuration !default;
$primeIconFontSize: 1rem !default;
$divider: 1px solid $shade300 !default;
$inlineSpacing: 0.5rem !default;
$disabledOpacity: 0.6 !default;
$maskBg: rgba(0, 0, 0, 0.4) !default;
$loadingIconFontSize: 2rem !default;
$errorColor: #e24c4c !default;

//upload
$fileUploadProgressBarHeight: 0.25rem !default;
$fileUploadContentPadding: 2rem 1rem !default;
$fileUploadContentHoverBorder: 1px dashed $primaryColor !default;
$fileUploadFileBorder: 1px solid $shade300 !default;
$fileUploadFilePadding: 1rem !default;

$tableBodyRowBg: $shade000 !default;
$tableBodyRowTextColor: $shade700 !default;
$tableBodyRowEvenBg: scale-color($tableBodyRowBg, $lightness: -1%) !default;
$tableBodyRowHoverBg: $shade200 !default;
$tableBodyRowTextHoverColor: $shade900 !default;
$tableBodyCellBorder: 1px solid $shade300 !default;
$tableBodyCellBorderWidth: 0 0 1px 0 !default;
$tableBodyCellPadding: 1rem 1rem !default;
