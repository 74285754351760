.p-fileupload .p-button {
    background-color: $secondaryButtonTextColor;
    color: white;
    border-color: $secondaryButtonTextColor;
}

.p-fileupload .p-button:hover {
    background-color: $secondaryButtonTextColor;
    border-color: $secondaryButtonTextColor;
}
